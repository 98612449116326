import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '1',
        image: "/images/one.png",
        title: 'Products',
        description: 'Ideate and build products with the right moat. '
    },
    {
        icon: '2',
        image: "/images/two.png",
        title: 'Strategy',
        description: 'Design compelling product strategies.'
    },
    {
        icon: '3',
        title: 'Business',
        image: "/images/three.png",
        description: 'Revamp and transform your existing business.'
    },
    {
        icon: '4',
        title: 'Marketing',
        image: "/images/four.png",
        description: 'Hit the market the right way.'
    },
]

const ServiceFour = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon" style={{backgroundPosition:"center",backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage:`url(${val.image})`}}>
                                <div className="line"></div>
                                {/* {val.icon} */}
                            </div>
                            <div className="content">
                                <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFour;