import React from "react";
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CircleProgress from "../elements/progressbar/CircleProgress";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import ServiceFour from "../elements/service/ServiceFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from "../common/footer/FooterTwo";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
  {
    image: "/images/bg/slideone.png",
    title: "Ideate. Create. Plan. Build. Innovate",
    description: "We not only help you plan, we execute!",
  },
  {
    image: "/images/bg/slidetwo.png",
    title: "We Innovate!",
    description: "Always figuring out great ways to build for you.",
  },
];

const Startup = () => {
  return (
    <>
      <SEO title="Unshelled" />
      <main className="page-wrapper" id="home-p">
        <HeaderOne
          btnStyle="btn-small btn-icon round"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        <div className="button-group mt--30">
                          <a
                          style={{cursor:"pointer"}}
                            onClick={(e) => {
                              e.preventDefault();

                              document
                                .querySelector("#contact-us")
                                .scrollIntoView({
                                  behavior: "smooth",
                                  block: "start",
                                });
                            }}
                            className="btn-default btn-large round"
                            // target="_blank"
                            // href=""
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}

        <Separator />
        <div id="about-us" className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--25">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="About us"
                  title="Who we Are"
                  description=""
                />
              </div>
            </div>
            <div className="col-lg-10 offset-lg-1 mt--10">
              <p>
                Digital clients or not, at our firm, we go on a journey with
                your product to help you see, think and build different. We are
                Unshelled!
              </p>
            </div>
          </div>
        </div>

        <Separator />
        <div className="rwt-timeline-area rn-section-gap" id="">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Working Process"
                  title="Our Working Process."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 mt--50">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap" id="our-services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we offer"
                  title="Services we provide for you."
                  description=""
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        <div id="contact-us">
          <FooterTwo />
        </div>
      </main>
    </>
  );
};

export default Startup;
