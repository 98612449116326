import React from "react";
import { Link } from "react-router-dom";

const Nav = ({onClose=f=>f}) => {
  return (
    <ul className="mainmenu">
      <li>
        <Link onClick={(e) => {
          e.preventDefault();
          
          onClose();

            document
            .querySelector("#home-p")
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }}
        
        to="/">Home</Link>
      </li>

      <li>
        <Link to="#our-services"
         onClick={(e) => {
          e.preventDefault();
          
          onClose();

            document
            .querySelector("#our-services")
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }}
        
        >Services</Link>
      </li>
      <li>
        <Link
          to=""
          onClick={(e) => {
            e.preventDefault();
            onClose();
            document
              .querySelector("#about-us")
              .scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          About
        </Link>
      </li>

      <li className="has-droupdown">
        <Link to="#contact-us"
         onClick={(e) => {
          e.preventDefault();
          onClose();
          document
            .querySelector("#contact-us")
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }}
        >Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
