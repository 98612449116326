import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import Logo from "../logo/Logo";
import {Link} from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

const callToActionData = {
    title: "Ready to start creating a standard website?",
    subtitle: "Digital clients or not, at our firm, we go on a journey with your product to help you see, think and build different.We are Unshelled!",
    btnText: "Contact Us",
}

const CalltoActionSeven = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-md-8">
                        <div className="inner">
                            <div className="content text-left">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/Unshelled.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/Unshelled.png`}
                                />
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p className="subtitle">{callToActionData.subtitle}</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="rn-footer-widget">
                                    <h4 style={{fontSize:20}} className="title">Stay With Us</h4>
                                    <div className="inner">
                                        <h6 style={{fontSize:13}} className="subtitlev">Email: <a href="mailto:info@unshelled.org">info@unshelled.org</a> </h6>
                                        <h6 style={{fontSize:13}} className="subtitlev">Phone:  08120280055</h6>
                                        <h6 style={{fontSize:13}} className="subtitlev">Address: 30, Turnbull road, Ikoyi, Lagos state.</h6>
                                        <ul className="social-icon social-default justify-content-start" style={{marginTop:20}}>
                                            <li><a href="https://web.facebook.com/The-Unshelled-109527325065356/?_rdc=1&_rdr" target="_blank"><FiFacebook /></a></li>
                                            <li><a href='https://twitter.com/TheUnshelled'><FiTwitter /></a></li>
                                            <li><a href="https://www.instagram.com/theunshelled/"><FiInstagram /></a></li>
                                            {/* <li><Link to="linkdin.com"><FiLinkedin /></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSeven;